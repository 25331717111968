<template>
  <div class="put_wrap">
    <div class="putques">
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-radio-group v-model="form.guideType">
            <el-radio :label="1">提问给残联机构</el-radio>
            <el-radio :label="2">提问给专家</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.guideType == 1">
          <div class="row flexa">
            <p class="changes">选择残联机构</p>
            <div class="selcet flex">
              <div>
                <ThreeLink
                    :codeType="'provinceid'"
                    :codeLevel="'-1'"
                    classed="classed"
                    :changeonselect="'true'"
                    placeholder="请选择省市区残联"
                    @setMValue="
                    setScasValue($event, form, [
                      'province',
                      'city',
                      'threeCity',
                    ])
                  "
                ></ThreeLink>
                <span >残联</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="form.guideType == 2">
          <div class="row flexa">
            <p class="changes">选择专家</p>
            <div class="selcet flex">
              <div>
                <el-select v-model="form.sysUserId" placeholder="请选择">
                  <el-option
                      v-for="(item,index) in selectList"
                      :key="index"
                      :label="item.realname"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-input
              v-model="form.name"
              placeholder="请输入想要提问的标题"
          ></el-input
          >
        </el-form-item>
        <el-form-item>
          <div class="textareas ">
            <el-input
                type="textarea"
                v-model="form.content"
                placeholder="请详细描述您想要提出的问题"
            ></el-input>
            <div class="flexa send">
              <div class="ima gecode flexs">
                <ImageCode
                    @temp="getTime"
                    @changeCode="getchangeCode"
                    ref="myImageCode"
                ></ImageCode>
              </div>
              <el-button type="primary" @click="addDisabledJiuYe"
              >提交
              </el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import ThreeLink from "@/components/selectComponents/ThreeLink";
import ImageCode from "@/components/login/ImageCode";

export default {
  components: {ThreeLink, ImageCode},
  data() {
    return {
      selectList:[],
      form: {
        name: '',
        content: '',
        guideType: 1,
        code: '',
        temp:'',
        province: '',
        city: '',
        threeCity: '',
        sysUserId:'',
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    //专家
    specialist(){
      this.$axios.get('/api/app-jycy-henan/henan/guide/getSysUserList').then(res =>{
          this.selectList = res.data.data;
      })
    },
    //省市区三级联动
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {id: id},
      });
      window.open(href, "_blank");
    },
    getTime(val){
      this.form.temp = val;
    },
    getchangeCode(val){
      this.form.code = val;
    },
    async addDisabledJiuYe() {
      if (this.form.content.length <= 0 || this.form.content == null) {
        this.$message.warning("不能发送空内容");
        return;
      }
      let res = await this.$axios.post('/api/app-jycy-henan/henan/guide/ask',this.form);
      if (res.data.success) {
        this.$message.success(res.data.msg);
        for (let key in this.form) {
          if (typeof this.form[key] === "string") {
            this.form[key] = "";
          }
        }
      } else {
        this.$message.error(res.data.msg);
      }

      console.log(res);
    },
  },
  created() {
    this.specialist();
  }
};
</script>
<style scoped lang="less">
@import "~assets/css/henan/putQuestion";

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
